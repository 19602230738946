import React from 'react'
import { Stack, Text, Tooltip, Whisper } from 'rsuite'

import './style.css'

const HelpText = ({
    title,
    text,
    placement
}) => {
    return (
        <Whisper
            placement={placement || "top"}
            speaker={
                <Tooltip>
                    <div className='help-text-container'>
                        <Stack spacing={7} direction='column'>
                            {title && <Text as="u" weight='bold'>{title}</Text>}
                            <Text align='justify'>{text}</Text>
                        </Stack>
                    </div>
                </Tooltip>
            }
        >
            <div className="help-text-icon">?</div>
        </Whisper>
    )
}

export default HelpText
