import api from '../services/api.js';

const resource = '/estatisticas'

const getByCnae = async ({ idEmpresa, pageNumber, itemsPerPage, sortColumn, sortType, cnae, municipio, regiaoImediata, regiaoIntermediaria, uf,
    faixaCapitalSocial, faixaIdadeEmpresa, exibirCnaeSemCliente, opcaoSimples, opcaoMei, formaTributacao }) => {
    const response = await api.get(`${resource}/cnae`, {
        params: {
            idEmpresa,
            pageNumber,
            itemsPerPage,
            sortColumn,
            sortType,
            cnae,
            municipio,
            regiaoImediata,
            regiaoIntermediaria,
            uf,
            faixaCapitalSocial,
            faixaIdadeEmpresa,
            exibirCnaeSemCliente,
            opcaoSimples,
            opcaoMei,
            formaTributacao
        }
    });
    return response.data;
}


const getByUF = async ({ idEmpresa, pageNumber, itemsPerPage, sortColumn, sortType, cnae, municipio, regiaoImediata, regiaoIntermediaria, uf,
    faixaCapitalSocial, faixaIdadeEmpresa, exibirCnaeSemCliente, opcaoSimples, opcaoMei, formaTributacao }) => {
    const response = await api.get(`${resource}/uf`, {
        params: {
            idEmpresa,
            pageNumber,
            itemsPerPage,
            sortColumn,
            sortType,
            cnae,
            municipio,
            regiaoImediata,
            regiaoIntermediaria,
            uf,
            faixaCapitalSocial,
            faixaIdadeEmpresa,
            exibirCnaeSemCliente,
            opcaoSimples,
            opcaoMei,
            formaTributacao
        }
    });
    return response.data;
}

const getFaixaCapitalSocial = async params => {
    const arrayCodigos = params && params.arrayCodigos
    const response = await api.get(`${resource}/faixacapitalsocial`, { params: { arrayCodigos } })
    return response.data;
}

const getFaixaIdadeEmpresa = async params => {
    const arrayCodigos = params && params.arrayCodigos
    const response = await api.get(`${resource}/faixaidadeempresa`, { params: { arrayCodigos } })
    return response.data;
}

export const useApiEstatisticas = () => ({
    getByCnae,
    getByUF,
    getFaixaCapitalSocial,
    getFaixaIdadeEmpresa
})