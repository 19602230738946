import { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Button, Col, FlexboxGrid, Loader, Panel, Popover, Table, Whisper, useToaster } from "rsuite";

import HelpText from "../../components/HelpText";
import { showMessage } from "../../components/ShowMessage";
import Title from "../../components/Title";

import { useApiLeads } from "../../hooks/useApiLeads";

import { AuthContext } from "../../contexts/authContext";
import { EmpresaContext } from "../../contexts/empresaContext";

import EmailIcon from '@rsuite/icons/Email';
import SearchIcon from '@rsuite/icons/Search';
import SortDownIcon from '@rsuite/icons/SortDown';
import TaskIcon from '@rsuite/icons/Task';

import { enumNivelUsuario } from "../../services/enum";

import './style.css';

const { Column, HeaderCell, Cell } = Table;

const tableLeads = {
    cellHeight: 46,
    itemsPerPage: 6,
    itemsDisplayed: 5,
    height: 46 * 5 + 40 //cell * displayed + header
}

const FixedLoader = () => (
    <Loader
        content="Carregando..."
        style={{
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            bottom: '0',
            background: '#f5f5f5',
            width: '90%',
        }}
    />
);
const CellParametro = ({ rowData, dataKey, ...props }) => {
    const speaker = (
        <Popover title="Parâmetros">
            <div>
                <pre>
                    {JSON.stringify(rowData.parametros, null, 2)}
                </pre>
            </div>
        </Popover>
    );

    return (
        <Cell {...props}>
            <Whisper placement="leftStart" speaker={speaker}>
                <SearchIcon />
            </Whisper>
        </Cell>
    );
};

const Dashboard = () => {
    const { empresaId, permiteTelaSelecaoLeads } = useContext(EmpresaContext)
    const { user } = useContext(AuthContext)

    const apiLeads = useApiLeads();
    const toaster = useToaster();

    const [leadsGerados, setLeadsGerados] = useState();
    const [leadsEmGeracao, setLeadsEmGeracao] = useState();

    const [downloading, setDownloading] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);

    const [loadingGerados, setLoadingGerados] = useState(false);
    const [loadingEmGeracao, setLoadingEmGeracao] = useState(false);

    const getLeadsGerados = async firstLoad => {
        setLoadingGerados(true);
        try {
            const data = await apiLeads.listLeads({
                start: firstLoad ? 0 : leadsGerados ? leadsGerados.length : 0,
                itemsPerPage: tableLeads.itemsPerPage,
                idEmpresa: empresaId,
                concluido: true
            });
            if (!leadsGerados || firstLoad) setLeadsGerados(data);
            else setLeadsGerados([...leadsGerados, ...data]);
        } catch (error) {
            setLeadsGerados();
        }
        setLoadingGerados(false);
    };
    const getLeadsEmGeracao = async firstLoad => {
        setLoadingEmGeracao(true);
        try {
            const data = await apiLeads.listLeads({
                start: firstLoad ? 0 : leadsGerados ? leadsGerados.length : 0,
                itemsPerPage: tableLeads.itemsPerPage,
                idEmpresa: empresaId,
                concluido: false
            });
            if (!leadsEmGeracao || firstLoad) setLeadsEmGeracao(data);
            else setLeadsEmGeracao([...leadsEmGeracao, ...data]);
        } catch (error) {
            setLeadsEmGeracao();
        }
        setLoadingEmGeracao(false);
    };

    useEffect(() => {
        getLeadsGerados(true);
        getLeadsEmGeracao(true);
        // eslint-disable-next-line
    }, [empresaId])

    const handleLeadsDownlaodButton = async fileName => {
        if (downloading) return false;
        setDownloading(true);

        try {
            await apiLeads.downloadLeads({
                idEmpresa: empresaId,
                fileName: fileName
            })
            showMessage({ toaster, successMessage: 'Download iniciado com sucesso.' })
        } catch (error) {
            showMessage({ toaster, errorObject: error });
        }
        setDownloading(false);
    }
    const handleLeadsEmailButton = async fileName => {
        if (sendingEmail) return false;
        setSendingEmail(true);

        try {
            await apiLeads.emailLeads({
                idEmpresa: empresaId,
                fileName: fileName
            })
            showMessage({ toaster, successMessage: 'E-mail enviado com sucesso.' })
        } catch (error) {
            showMessage({ toaster, errorObject: error });
        }
        setSendingEmail(false);
    }
    const handleScrollTableLeadsGerados = (x, y) => {
        const contextHeight = leadsGerados.length * tableLeads.cellHeight;
        const displayed = tableLeads.cellHeight * tableLeads.itemsDisplayed;
        const bottom = Math.abs(y) + displayed

        if (bottom <= contextHeight && bottom > contextHeight - (tableLeads.cellHeight / 2)) getLeadsGerados();
    }
    const handleScrollTableLeadsEmGeracao = (x, y) => {
        const contextHeight = leadsGerados.length * tableLeads.cellHeight;
        const displayed = tableLeads.cellHeight * tableLeads.itemsDisplayed;
        const bottom = Math.abs(y) + displayed

        if (bottom <= contextHeight && bottom > contextHeight - (tableLeads.cellHeight / 2)) getLeadsGerados();
    }

    return (
        <>
            <Title text="Dashboard" />
            <FlexboxGrid>
                <FlexboxGrid.Item as={Col} colspan={24} lg={12} md={24}>
                    <Panel header="Seus leads gerados" className="data-panel">
                        <Table
                            virtualized
                            data={leadsGerados}
                            height={tableLeads.height}
                            shouldUpdateScroll={false}
                            onScroll={handleScrollTableLeadsGerados}
                        >
                            <Column minWidth={240} flexGrow={1}>
                                <HeaderCell>Data</HeaderCell>
                                <Cell dataKey="data" />
                            </Column>
                            <Column width={60} align="right">
                                <HeaderCell>Leads</HeaderCell>
                                <Cell dataKey="custo" />
                            </Column>
                            <Column width={60} align="center">
                                <HeaderCell>Param</HeaderCell>
                                <CellParametro dataKey="parametros" />
                            </Column>
                            <Column width={45} fixed='right' align="center">
                                <HeaderCell>
                                    <HelpText text='Enviar o arquivo por e-mail' />
                                </HeaderCell>
                                <Cell className="table-cell-button">
                                    {rowData =>
                                        rowData.download &&
                                        <Button appearance='ghost' color='blue' size='sm' onClick={() => handleLeadsEmailButton(rowData['download'])}>
                                            {sendingEmail ? <Loader /> : <EmailIcon />}
                                        </Button>
                                    }
                                </Cell>
                            </Column>
                            <Column width={45} fixed='right' align="center">
                                <HeaderCell>
                                    <HelpText text='Fazer o download do arquivo' />
                                </HeaderCell>
                                <Cell className="table-cell-button">
                                    {rowData =>
                                        rowData.download &&
                                        <Button appearance='ghost' color='blue' size='sm' onClick={() => handleLeadsDownlaodButton(rowData['download'])}>
                                            {downloading ? <Loader /> : <SortDownIcon />}
                                        </Button>
                                    }
                                </Cell>
                            </Column>
                            {user.nivel >= enumNivelUsuario.Admin && permiteTelaSelecaoLeads
                                ? <Column width={45} fixed='right' align="center">
                                    <HeaderCell>
                                        <HelpText text='Acessar a tela de seleção de Leads para ser enviado para sua API ou ser buscado usando nossa API' />
                                    </HeaderCell>
                                    <Cell className="table-cell-button">
                                        {rowData =>
                                            rowData.permiteTelaSelecaoLeads === 'S'
                                                ? <Link to="/envialeads" state={{ idLeads: rowData.idLeads }}>
                                                    <Button appearance='ghost' color='blue' size='sm'>
                                                        <TaskIcon />
                                                    </Button>
                                                </Link>
                                                : <></>
                                        }
                                    </Cell>
                                </Column>
                                : <></>
                            }
                            <Column width={10} fixed='right'>
                                <HeaderCell></HeaderCell>
                                <Cell></Cell>
                            </Column>
                        </Table>
                        {loadingGerados && <FixedLoader />}
                    </Panel>
                </FlexboxGrid.Item>

                {leadsEmGeracao && leadsEmGeracao.length > 0 &&
                    <FlexboxGrid.Item as={Col} colspan={24} lg={12} md={24}>
                        <Panel header="Seus leads em geração" className="data-panel">
                            <Table
                                virtualized
                                data={leadsEmGeracao}
                                height={tableLeads.height}
                                shouldUpdateScroll={false}
                                onScroll={handleScrollTableLeadsEmGeracao}
                            >
                                <Column minWidth={240} flexGrow={1}>
                                    <HeaderCell>Data</HeaderCell>
                                    <Cell dataKey="data" />
                                </Column>
                                <Column width={60} align="right">
                                    <HeaderCell>Leads</HeaderCell>
                                    <Cell dataKey="custo" />
                                </Column>
                                <Column width={60} align="center">
                                    <HeaderCell>Param</HeaderCell>
                                    <CellParametro dataKey="parametros" />
                                </Column>

                            </Table>
                            {loadingEmGeracao && <FixedLoader />}
                        </Panel>
                    </FlexboxGrid.Item>}

            </FlexboxGrid>
        </>
    )
}

export default Dashboard
