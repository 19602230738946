const isEmpty = value => {
    if (typeof value === 'string') {
        return value.trim().length === 0 // Verifica se a string está vazia ou só tem espaços
    } else if (typeof value === 'object' && value !== null) {
        return Object.keys(value).length === 0 // Verifica se o objeto não tem propriedades
    }
    return true // Considera outros tipos (null, undefined) como vazios
}

const isEmail = text => {
    return /\S+@\S+\.\S+/.test(text.trim())
}

const dateFromString = text => {
    if (text === '') return null

    const parts1 = text.split('T')[0].split('-')
    if (parts1.length) return new Date(parts1[0], parts1[1] - 1, parts1[2])

    const parts2 = text.split('/')
    if (parts2.length) return new Date(parts2[2], parts2[1] - 1, parts2[0])

    return null
}

// Função para verificar se os objetos têm as mesmas propriedades e valores
const objectCompare = (object1, object2) => {
    // Obtém as chaves dos objetos
    const keysObject1 = Object.keys(object1);
    const keysObject2 = Object.keys(object2);

    // Verifica se o número de chaves é o mesmo
    if (keysObject1.length !== keysObject2.length) {
        return false;
    }

    // Verifica se as propriedades e os valores são os mesmos
    for (let key of keysObject1) {
        if (object1[key] !== object2[key]) {
            return false;
        }
    }

    // Se todas as propriedades e valores forem iguais, os objetos são iguais
    return true;
};

const formataCEP = cep => {
    if (!cep) return ''

    cep = cep.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    cep = cep.replace(/^(\d{5})(\d{3})$/, '$1-$2'); // Adiciona o hífen na posição correta

    return cep;
}

const formataTelefone = telefone => {
    if (!telefone) return ''

    telefone = telefone.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    if (telefone.length === 8) {
        telefone = telefone.replace(/^(\d{4})(\d{4})$/, '$1-$2');
    } else if (telefone.length === 10) {
        telefone = telefone.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
    } else if (telefone.length === 11) {
        telefone = telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    }

    return telefone;
}

const formataCNPJ = cnpj => {
    if (!cnpj) return ''

    cnpj = cnpj.replace(/\D/g, '');
    if (cnpj.length === 14) cnpj = cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");

    return cnpj
}

// Comparação de objetos
const isDeepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !isDeepEqual(obj1[key], obj2[key])) {
            return false;
        }
    }

    return true;
}

export {
    dateFromString, formataCEP, formataCNPJ, formataTelefone, isDeepEqual, isEmail,
    isEmpty, objectCompare
}

export const mask = {
    cep: [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
    cnpj: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/],
    cnpj_basico: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/]
}

