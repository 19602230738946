import { useContext, useEffect, useState } from "react";
import { Stack, Text, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/ShowMessage";

import { EmpresaContext } from "../../contexts/empresaContext";

import { useApiEstatisticas } from '../../hooks/useApiEstatisticas';
import { useApiLeads } from '../../hooks/useApiLeads';

import CustomHeader from "./CustomHeader";
import { FormatoDemonstracao, getDataTableColumns } from "./dataTableColumns";

import './style.css';

const defaultFilterFields = {
    cnae: [],
    municipio: [],
    regiaoImediata: [],
    regiaoIntermediaria: [],
    uf: [],
    faixaCapitalSocial: [],
    faixaIdadeEmpresa: [],
    exibirCnaeSemCliente: false,
    opcaoMei: 'T',
    opcaoSimples: 'T',
    formaTributacao: []
}

const Estatistica = () => {
    const { empresaId, setEmpresaReadOnly, saldoCredito } = useContext(EmpresaContext)

    const apiEstatisticas = useApiEstatisticas()
    const apiLeads = useApiLeads()

    const [dadosPorCnae, setDadosPorCnae] = useState({})
    const [dadosPorUF, setDadosPorUF] = useState({})
    const [formatoDemonstracao, setFormatoDemonstracao] = useState(FormatoDemonstracao.cnae_uf_municipio)

    const [dataTableParams, setDataTableParams] = useState()
    const [filterParams, setFilterParams] = useState(defaultFilterFields)
    const [filterFields, setFilterFields] = useState(defaultFilterFields)

    const [loading, setLoading] = useState(true)
    const [leadsParams, setLeadsParams] = useState()
    const [openConfirm, setOpenConfirm] = useState(false)
    const [messageConfirm, setMessageConfirm] = useState()

    const getEstatisticas = async () => {
        setLoading(true)
        setEmpresaReadOnly(true)
        if (!empresaId || !dataTableParams) {
            setDadosPorCnae({})
            setDadosPorUF({})
        } else {
            try {
                if (formatoDemonstracao === FormatoDemonstracao.cnae_uf_municipio) {
                    const data = await apiEstatisticas.getByCnae({ ...dataTableParams, ...filterParams, idEmpresa: empresaId })
                    setDadosPorCnae({
                        data: data.rows,
                        total: data.total,
                        filtered: data.filtered
                    })
                    setDadosPorUF({})
                } else {
                    const data = await apiEstatisticas.getByUF({ ...dataTableParams, ...filterParams, idEmpresa: empresaId })
                    setDadosPorUF({
                        data: data.rows,
                        total: data.total,
                        filtered: data.filtered
                    })
                    setDadosPorCnae({})
                }
            } catch (error) {
                setDadosPorCnae({})
                setDadosPorUF({})
                showError(error)
            }
        }
        setLoading(false)
        setEmpresaReadOnly(false)
    }

    useEffect(() => {
        (async () => await getEstatisticas())()
        // eslint-disable-next-line
    }, [empresaId, dataTableParams, filterParams, formatoDemonstracao])

    const handleClickApplyFilter = () => {
        setFilterParams(filterFields)
    }

    const handleClickClearFilter = () => {
        setFilterParams(defaultFilterFields)
        setFilterFields(defaultFilterFields)
    }

    const handleClickCellButton = params => {
        const qtdeLeads = params.leadsCount || 0
        const novoSaldo = saldoCredito - qtdeLeads;

        if (novoSaldo < 0) {
            showError({ message: `Não há saldo suficiente para gerar os ${qtdeLeads.toLocaleString()}.` })
            return
        }

        const msg = `Serão gerados ${qtdeLeads.toLocaleString()} Leads e enviados por e-mail. Restará um saldo de ${novoSaldo.toLocaleString()} créditos. Confirma a geração?`

        setLeadsParams(params)
        setMessageConfirm(msg)
        setOpenConfirm(true)
    }
    const handleClickConfirmLeads = () => {
        const params = {
            ...leadsParams,
            origemEstatistica: 'S',
            idEmpresa: empresaId,
            considerarApenasMatriz: 'S',
            desconsiderarCliente: 'B',
            considerarRecuperacaoJudicial: 'S',
            opcaoMei: filterParams.opcaoMei,
            opcaoSimples: filterParams.opcaoSimples
        }

        //if (filterParams.cnae.length) params.cnae = filterParams.cnae

        if (filterParams.formaTributacao.length) params.formaTributacao = filterParams.formaTributacao
        if (filterParams.faixaCapitalSocial.length) params.faixaCapitalSocial = filterParams.faixaCapitalSocial
        if (filterParams.faixaIdadeEmpresa.length) params.faixaIdadeEmpresa = filterParams.faixaIdadeEmpresa
        if (filterParams.uf.length) params.uf = filterParams.uf
        if (filterParams.regiaoImediata.length) params.regiaoImediata = filterParams.regiaoImediata
        if (filterParams.regiaoIntermediaria.length) params.regiaoIntermediaria = filterParams.regiaoIntermediaria
        if (filterParams.municipio.length) params.municipio = filterParams.municipio

        apiLeads.getLeads(params)

        setOpenConfirm(false)
        showSucess()
    }

    const toaster = useToaster();
    const showError = error => showMessage({ toaster, errorObject: error })
    const showSucess = () => showMessage({ toaster, successMessage: `Solicitação concluída com sucesso` })

    const dataTableColumns = getDataTableColumns({ onClickCellButton: handleClickCellButton, saldoCredito: saldoCredito, formatoDemonstracao: formatoDemonstracao })

    return (
        <Stack direction="column" alignItems={null}>
            <div className='datatable-title'>
                <Stack direction="row" spacing={5}>
                    Estatística
                    <Text size="sm" >
                        {`[${formatoDemonstracao}]`}
                    </Text>
                </Stack>
            </div>
            <DataTable
                data={formatoDemonstracao === FormatoDemonstracao.cnae_uf_municipio ? dadosPorCnae : dadosPorUF}
                dataTableColumns={dataTableColumns}
                placeholderSearch='cnae'
                loading={loading}
                className={'estatistica-cnae-table'}
                headerHeight={80}
                rowHeight={38}
                minHeight={475}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                CustomHeader={<CustomHeader
                    loading={loading}
                    defaultFilterFields={defaultFilterFields}
                    filterFields={filterFields}
                    setFilterFields={setFilterFields}
                    formatoDemonstracao={formatoDemonstracao}
                    setFormatoDemonstracao={setFormatoDemonstracao}
                    onClickApplyFilter={handleClickApplyFilter}
                    onClickClearFilter={handleClickClearFilter}
                    showError={showError}
                />}
            />
            <Confirm
                open={openConfirm}
                message={messageConfirm}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickConfirmLeads}
            />
        </Stack >
    )
}

export default Estatistica