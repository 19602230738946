import { Col, FlexboxGrid, Stack } from "rsuite";

import HelpText from "../../components/HelpText";

import './style.css';

const DadosField = ({ label, value, size, style, styleContainer, helpText, helpTitle }) => {
    return (
        <FlexboxGrid.Item
            as={Col}
            lg={(size && size.lg) || 24}
            md={(size && size.md) || 24}
            sm={(size && size.sm) || 24}
            style={styleContainer || null}
        >
            <div className="field-container">
                <Stack spacing={10}>
                    <label>{label}</label>
                    {helpText && <HelpText title={helpTitle} text={helpText} />}
                </Stack>
                <div className="field-value" style={style || null}>
                    {value}
                </div>
            </div>
        </FlexboxGrid.Item>
    )
}

export default DadosField