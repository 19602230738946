import { useContext, useEffect, useState } from "react";
import { Avatar, Col, Form, Grid, InputGroup, Radio, RadioGroup, Row, Stack, Tag, Toggle, useToaster } from "rsuite";

import Confirm from "../../components/Confirm";
import DataModal from "../../components/DataModal";
import DataTable from "../../components/DataTable";
import { showMessage } from "../../components/ShowMessage";

import { EmpresaContext } from "../../contexts/empresaContext";
import { useApiUsuario } from "../../hooks/useApiUsuario";
import { isEmail, isEmpty } from "../../services/utils";

const Usuario = () => {
    const { empresaId } = useContext(EmpresaContext)

    const apiUsuario = useApiUsuario();

    const [usuarios, setUsuarios] = useState({});
    const [usuario, setUsuario] = useState({});

    const [dataTableParams, setDataTableParams] = useState()
    const [goToLastPage, setGoToLastPage] = useState()

    const [waiting, setWaiting] = useState(false);
    const [loading, setLoading] = useState(true);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);

    const [deleteMode, setDeleteMode] = useState(false)

    const getUsuarios = async () => {
        setLoading(true)
        if (!empresaId || !dataTableParams) {
            setUsuarios({});
        } else {
            try {
                const data = await apiUsuario.getUsuarios({ ...dataTableParams, idEmpresa: empresaId })
                setUsuarios({
                    data: data.rows,
                    total: data.total,
                    filtered: data.filtered
                });
            } catch (error) {
                setUsuarios({});
                showError(error)
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        (async () => await getUsuarios())()
        // eslint-disable-next-line
    }, [empresaId, dataTableParams])

    const getUsuario = async id => {
        try {
            const data = await apiUsuario.getUsuario(id)
            return {
                idEmpresa: data.idEmpresa,
                idUsuario: data.idUsuario,
                emailUsuario: data.emailUsuario,
                nomeUsuario: data.nomeUsuario,
                nivelUsuario: data.nivelUsuario,
                cargoUsuario: data.cargoUsuario,
                fotoPerfilUsuario: data.fotoPerfilUsuario,
                ativo: data.ativo
            }
        } catch (error) {
            showError(error)
        }
    }

    const handleClickNewButton = () => {
        setUsuario({
            idEmpresa: empresaId,
            idUsuario: null,
            emailUsuario: '',
            nomeUsuario: '',
            nivelUsuario: 1,
            cargoUsuario: '',
            fotoPerfilUsuario: null,
            ativo: true
        });
        setDeleteMode(false);
        setOpenEditModal(true);
    }
    const handleClickEditButton = async id => {
        const data = await getUsuario(id)
        if (data) {
            setUsuario(data)
            setDeleteMode(false);
            setOpenEditModal(true);
        }
    }
    const handleClickDeleteButton = async id => {
        const data = await getUsuario(id)
        if (data) {
            setUsuario(data)
            setDeleteMode(true);
            setOpenEditModal(true);
        }
    }

    const handleClickCancelButton = () => {
        setOpenEditModal(false)
        setWaiting(false)
    }

    const handleClickYesButton = async () => {
        setOpenConfirm(false)
        setWaiting(true)
        try {
            const response = deleteMode ? await apiUsuario.deleteUsuario(usuario.idUsuario) : await apiUsuario.saveUsuario(usuario)

            showSucess()
            handleClickCancelButton()
            await getUsuarios()

            if (response.status === 201) {
                if (!dataTableParams.sortColumn || !dataTableParams.sortType ||
                    (dataTableParams.sortColumn === 'idUsuario' && dataTableParams.sortType === 'asc')) {
                    setGoToLastPage(true)
                }
            }
        } catch (error) {
            showError(error)
        }
    }

    const toaster = useToaster();
    const showSucess = () => showMessage({ toaster, successMessage: `${deleteMode ? 'Exclusão' : 'Gravação'} concluída com sucesso` })
    const showError = error => showMessage({ toaster, errorObject: error, executeFirst: () => setWaiting(false) })

    const customCellContent = (rowData, col) => <div style={!rowData['ativo'] ? { color: 'red' } : {}}>{rowData[col.dataKey]}</div>

    const dataTableColumns = {
        editButtonColumn: {
            dataKey: 'idUsuario',
            onClick: handleClickEditButton
        },
        deleteButtonColumn: {
            dataKey: 'idUsuario',
            onClick: handleClickDeleteButton
        },
        columns: [
            {
                dataKey: 'fotoPerfilUsuario',
                avatar: true,
                width: 70,
                align: 'center'
            }, {
                dataKey: 'nomeUsuario',
                headerCell: 'Nome',
                flexGrow: 1,
                minWidth: 250,
                sortable: true,
                fullText: true,
                customCellContent: customCellContent
            }, {
                dataKey: 'emailUsuario',
                headerCell: 'e-mail',
                width: 250,
                sortable: true,
                fullText: true,
                customCellContent: customCellContent
            }, {
                dataKey: 'descrNivelUsuario',
                headerCell: 'Nível',
                width: 200,
                sortable: true,
                customCellContent: customCellContent
            }, {
                dataKey: 'cargoUsuario',
                headerCell: 'Cargo',
                width: 200,
                sortable: true,
                customCellContent: customCellContent
            }, {
                dataKey: 'ativo',
                headerCell: 'Ativo',
                width: 70,
                align: 'center',
                sortable: true,
                customCellContent: (rowData, col) => rowData[col.dataKey] ? "Sim" : <div style={{ color: 'red' }}>Não</div>
            }
        ]
    }

    const [showErrorMessage, setShowErrorMessage] = useState({
        emailUsuario: false,
        nomeUsuario: false
    })

    const formValidation = {
        emailUsuario: {
            validation: (input) => !isEmpty(input.value) && isEmail(input.value),
            message: 'Deve informar um e-mail válido',
        },
        nomeUsuario: {
            validation: (input) => !isEmpty(input.value),
            message: 'Nome do usuário é obrigatório',
        }
    }

    return (
        <Stack direction="column" alignItems={null}>
            <div className='datatable-title'>Cadastro de Usuários</div>

            <DataTable
                data={usuarios}
                dataTableColumns={dataTableColumns}
                placeholderSearch='id, nome, e-mail, nivel ou cargo'
                textNewButton='Novo Usuário'
                onClickNewButton={handleClickNewButton}
                loading={loading}
                setLoading={setLoading}
                onChangeParams={params => setDataTableParams(params)}
                goToLastPage={goToLastPage}
                setGoToLastPage={setGoToLastPage}
            />

            <DataModal
                title='Cadastro de Usuário'
                open={openEditModal}
                sizeModal='lg'
                waiting={waiting}
                textSubmitButton={deleteMode ? 'Excluir' : 'Gravar'}
                colorSubmitButton={deleteMode ? 'red' : 'green'}
                onClickSubmitButton={() => setOpenConfirm(true)}
                onClickCancelButton={handleClickCancelButton}
                checkFormValidation={!deleteMode}
                formValidation={formValidation}
                setShowErrorMessage={setShowErrorMessage}
            >
                <Grid fluid>
                    <Row>
                        <Col xs={24} md={8}>
                            <Stack direction='column' alignItems='center'>
                                <Avatar className='avatar-perfil-200' src={usuario.fotoPerfilUsuario} circle />
                            </Stack>
                        </Col>
                        <Col xs={24} md={16}>
                            <Form.Group controlId="ativo">
                                <Form.ControlLabel>Ativo</Form.ControlLabel>
                                <Stack spacing={20}>
                                    <Form.Control
                                        name="ativo"
                                        readOnly={deleteMode || !usuario.idUsuario}
                                        accepter={Toggle}
                                        size='lg'
                                        checkedChildren="Sim"
                                        unCheckedChildren="Não"
                                        checked={usuario.ativo}
                                        onChange={value => setUsuario({ ...usuario, ativo: value })}
                                    />
                                    {usuario &&
                                        !usuario.ativo &&
                                        <Stack.Item alignSelf="flex-end">
                                            <Tag size='lg' color="red">
                                                Inativo
                                            </Tag>
                                        </Stack.Item>
                                    }
                                </Stack>
                            </Form.Group>
                            <Form.Group controlId="emailUsuario">
                                <Form.ControlLabel>Email</Form.ControlLabel>
                                <InputGroup>
                                    <InputGroup.Addon> @</InputGroup.Addon>
                                    <Form.Control
                                        name="emailUsuario"
                                        type="email"
                                        readOnly={deleteMode || usuario.idUsuario}
                                        autoFocus={!usuario.idUsuario}
                                        value={usuario.emailUsuario}
                                        onChange={value => setUsuario({ ...usuario, emailUsuario: value.toLowerCase() })}
                                    />
                                </InputGroup>
                                <Form.ErrorMessage show={showErrorMessage.emailUsuario}>{formValidation.emailUsuario.message}</Form.ErrorMessage>
                                <Form.HelpText>Obrigatório</Form.HelpText>
                            </Form.Group>
                            <Form.Group controlId="nomeUsuario">
                                <Form.ControlLabel>Nome do Usuário</Form.ControlLabel>
                                <Form.Control
                                    name="nomeUsuario"
                                    readOnly={deleteMode}
                                    autoFocus={!deleteMode && usuario.idUsuario}
                                    value={usuario.nomeUsuario}
                                    onChange={value => setUsuario({ ...usuario, nomeUsuario: value })}
                                />
                                <Form.ErrorMessage show={showErrorMessage.nomeUsuario}>{formValidation.nomeUsuario.message}</Form.ErrorMessage>
                                <Form.HelpText>Obrigatório</Form.HelpText>
                            </Form.Group>
                            <Form.Group controlId="cargoUsuario">
                                <Form.ControlLabel>Cargo</Form.ControlLabel>
                                <Form.Control
                                    name="cargoUsuario"
                                    readOnly={deleteMode}
                                    value={usuario.cargoUsuario}
                                    onChange={value => setUsuario({ ...usuario, cargoUsuario: value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="nivelUsuario">
                                <div className="rs-form-control-label">Nível</div>
                                <Form.Control
                                    name="nivelUsuario"
                                    readOnly={deleteMode}
                                    accepter={RadioGroup}
                                    inline
                                    value={usuario.nivelUsuario}
                                    onChange={value => setUsuario({ ...usuario, nivelUsuario: value })}
                                >
                                    {empresaId === 1 && <Radio value={5}>Master</Radio>}
                                    <Radio value={3}>Administrador</Radio>
                                    <Radio value={1}>Usuário</Radio>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </Grid>
            </DataModal>
            <Confirm
                open={openConfirm}
                message={`Confirma a ${deleteMode ? 'exclusão' : 'gravação'} dos dados do Usuário?`}
                onClickNoButton={() => setOpenConfirm(false)}
                onClickYesButton={handleClickYesButton}
            />
        </Stack >
    )
}

export default Usuario