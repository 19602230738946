const textIPServidor = `Se não informar o campo, qualquer IP poderá consumir a API, caso informe, o sistema irá restringir 
o acesso ao(s) endereço(s) informado(s). Para informar vários endereços, separe-os por ponto e virgula(;).`

const titleRegiaoImediata = `Região Geográfica Imediata (Microregião)`
const textRegiaoImediata = `Uma região geográfica imediata é um agrupamento de municípios que se baseia em um centro urbano local.
Foi elaborado pelo IBGE em 2017 substituindo a microrregião`

const titleRegiaoIntermediaria = `Região Geográfica Intermediária (Mesorregião)`
const textRegiaoIntermediaria = `Uma região intermediária é um agrupamento de regiões imediatas que se articulam em torno de uma metrópole, 
capital regional ou centro urbano representativo. Foi elaborado pelo IBGE em 2017 substituindo a mesorregião`

export {
    textIPServidor,
    textRegiaoImediata,
    textRegiaoIntermediaria,
    titleRegiaoImediata,
    titleRegiaoIntermediaria
}

