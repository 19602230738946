import { useContext, useRef } from "react";
import { Button, Checkbox, Col, FlexboxGrid, Form, Panel, Radio, RadioGroup, Stack } from "rsuite";

import ComboSemPaginacao from "../../components/ComboSemPaginacao";
import SelectSalvaFiltro from "../../components/SelectSalvaFiltro";
import SelectTabelasRFB from "../../components/SelectTabelasRFB";
import VerticalTitle from "../../components/VerticalTitle";

import { useApiEstatisticas } from "../../hooks/useApiEstatisticas";
import { useApiRFBTabelas } from '../../hooks/useApiRFBTabelas';

import { enumNivelUsuario } from "../../services/enum";
import { textRegiaoImediata, textRegiaoIntermediaria, titleRegiaoImediata, titleRegiaoIntermediaria } from "../../services/text";
import { isDeepEqual } from "../../services/utils";

import { FormatoDemonstracao } from "./dataTableColumns";

import { AuthContext } from "../../contexts/authContext";

import './style.css';

const CustomHeader = (
    {
        loading,
        defaultFilterFields,
        filterFields,
        setFilterFields,
        formatoDemonstracao,
        setFormatoDemonstracao,
        onClickApplyFilter,
        onClickClearFilter,
        showError
    }
) => {
    const { user } = useContext(AuthContext)

    const apiRFB = useApiRFBTabelas()
    const apiEstatisticas = useApiEstatisticas()

    const handleSetDataRegiaoIntermediaria = async params => {
        return await apiRFB.getRegiaoIntermediaria({
            ...params,
            uf: filterFields.uf
        })
    }

    const handleSetDataRegiaoImediata = async params => {
        return await apiRFB.getRegiaoImediata({
            ...params,
            uf: filterFields.uf,
            regiaoImediata: filterFields.regiaoImediata
        })
    }

    const handleSetDataMunicipio = async params => {
        return await apiRFB.getMunicipios({
            ...params,
            uf: filterFields.uf,
            regiaoImediata: filterFields.regiaoImediata,
            regiaoIntermediaria: filterFields.regiaoIntermediaria
        })
    }

    const handleClickFormatoDemonstracao = () => {
        onClickApplyFilter()

        if (formatoDemonstracao === FormatoDemonstracao.cnae_uf_municipio) setFormatoDemonstracao(FormatoDemonstracao.uf_municipio_cnae)
        else setFormatoDemonstracao(FormatoDemonstracao.cnae_uf_municipio)
    }

    const handleClickClear = () => {
        if (salvaFiltroRef.current) salvaFiltroRef.current.clear()
        if (onClickClearFilter) onClickClearFilter()
    }

    const validateSalvaFiltro = () => {
        if (isDeepEqual(filterFields, defaultFilterFields)) {
            showError({ message: "Selecione algum parâmetro para salvar o filtro." })
            return false
        }

        return true
    }

    const selectCnaeRef = useRef(null)
    const selectFormaTributacao = useRef(null)
    const selectUfRef = useRef(null)
    const selectRegImediataRef = useRef(null)
    const selectRegIntermediariaRef = useRef(null)
    const selectMunRef = useRef(null)
    const selectFaixaCapitalRef = useRef(null)
    const selectFaixaIdadeRef = useRef(null)
    const salvaFiltroRef = useRef(null)

    //remover quando homologar
    const homologar = user.nivel >= enumNivelUsuario.Master

    return (
        <Panel bordered className="estatistica-cnae-custom-header">
            <VerticalTitle verticalTitle={"Filtros"}>
                <Form fluid>
                    <FlexboxGrid>
                        <FlexboxGrid.Item as={Col} colspan={24} lg={homologar ? 6 : 7} md={12}>
                            <SelectTabelasRFB
                                ref={selectUfRef}
                                controlName={"uf"}
                                controlLabel={"UF"}
                                block
                                apiSetData={params => apiRFB.getUFs(params)}
                                value={filterFields.uf}
                                onChange={value => setFilterFields({ ...filterFields, uf: value })}
                            />
                        </FlexboxGrid.Item>

                        {homologar &&
                            <FlexboxGrid.Item as={Col} colspan={24} lg={6} md={12}>
                                <SelectTabelasRFB
                                    ref={selectRegIntermediariaRef}
                                    controlName={"regiaoIntermediaria"}
                                    controlLabel={"Mesorregião (código IBGE)"}
                                    helpText={textRegiaoIntermediaria}
                                    helpTitle={titleRegiaoIntermediaria}
                                    block
                                    apiSetData={handleSetDataRegiaoIntermediaria}
                                    groupBy='uf_sigla_nome'
                                    customLabel={item => `${item.nome_regiao} (${item.codigo})`}
                                    customRender={item => item.nome_regiao}
                                    value={filterFields.regiaoIntermediaria}
                                    onChange={value => { setFilterFields({ ...filterFields, regiaoIntermediaria: value }) }}
                                />
                            </FlexboxGrid.Item>
                        }

                        {homologar &&
                            <FlexboxGrid.Item as={Col} colspan={24} lg={6} md={12}>
                                <SelectTabelasRFB
                                    ref={selectRegImediataRef}
                                    controlName={"regiaoImediata"}
                                    controlLabel={"Microrregião (código IBGE)"}
                                    helpText={textRegiaoImediata}
                                    helpTitle={titleRegiaoImediata}
                                    block
                                    apiSetData={handleSetDataRegiaoImediata}
                                    groupBy='uf_sigla_nome'
                                    customLabel={item => `${item.nome_regiao} (${item.codigo})`}
                                    customRender={item => item.nome_regiao}
                                    value={filterFields.regiaoImediata}
                                    onChange={value => { setFilterFields({ ...filterFields, regiaoImediata: value }) }}
                                />
                            </FlexboxGrid.Item>
                        }

                        <FlexboxGrid.Item as={Col} colspan={24} lg={homologar ? 6 : 7} md={12}>
                            <SelectTabelasRFB
                                ref={selectMunRef}
                                controlName={"municipio"}
                                controlLabel={"Município (código IBGE)"}
                                block
                                apiSetData={handleSetDataMunicipio}
                                groupBy='uf_sigla_nome'
                                customLabel={item => `${item.municipio} (${item.codigo_ibge})`}
                                customRender={item => item.municipio}
                                value={filterFields.municipio}
                                onChange={value => { setFilterFields({ ...filterFields, municipio: value }) }}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={6} md={12}>
                            <ComboSemPaginacao
                                ref={selectFaixaCapitalRef}
                                controlName={"faixaCapitalSocial"}
                                controlLabel={"Capital Social"}
                                block
                                apiSetData={apiEstatisticas.getFaixaCapitalSocial}
                                customLabel={item => item.descricao}
                                customRender={item => item.descricao}
                                value={filterFields.faixaCapitalSocial}
                                onChange={value => setFilterFields({ ...filterFields, faixaCapitalSocial: value })}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={4} md={12}>
                            <ComboSemPaginacao
                                ref={selectFaixaIdadeRef}
                                controlName={"faixaIdadeEmpresa"}
                                controlLabel={"Idade da Empresa"}
                                block
                                apiSetData={apiEstatisticas.getFaixaIdadeEmpresa}
                                customLabel={item => item.descricao}
                                customRender={item => item.descricao}
                                value={filterFields.faixaIdadeEmpresa}
                                onChange={value => setFilterFields({ ...filterFields, faixaIdadeEmpresa: value })}
                            />
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={homologar ? 14 : 24} md={24} >
                            <SelectTabelasRFB
                                ref={selectCnaeRef}
                                controlName={"cnae"}
                                controlLabel={"CNAE"}
                                block
                                apiSetData={params => apiRFB.getCnaes(params)}
                                value={filterFields.cnae}
                                onChange={value => setFilterFields({ ...filterFields, cnae: value })}
                            />
                        </FlexboxGrid.Item>

                        {/* Remover quando o Marcos homologar, no controller do server também tem para remover */}
                        {homologar &&
                            <FlexboxGrid.Item as={Col} colspan={24} lg={6} md={24}>
                                <ComboSemPaginacao
                                    ref={selectFormaTributacao}
                                    controlName={"formaTributacao"}
                                    controlLabel={"Forma de Tributação"}
                                    block
                                    apiSetData={params => apiRFB.getFormaTributacao(params)}
                                    customLabel={item => item.descricao}
                                    customRender={item => item.descricao}
                                    value={filterFields.formaTributacao}
                                    onChange={value => setFilterFields({ ...filterFields, formaTributacao: value })}
                                />
                            </FlexboxGrid.Item>
                        }

                        <FlexboxGrid.Item as={Col} colspan={24} lg={homologar ? 7 : 6} md={12}>
                            <Form.Group controlId="opcaoMei">
                                <Form.ControlLabel>Opção pelo MEI</Form.ControlLabel>
                                <Form.Control
                                    className="radio-group"
                                    accepter={RadioGroup}
                                    name="opcaoMei"
                                    inline
                                    appearance="picker"
                                    value={filterFields.opcaoMei}
                                    onChange={value => setFilterFields({ ...filterFields, opcaoMei: value })}
                                >
                                    <Radio value="T">Todos</Radio>
                                    <Radio value="S">Só optantes</Radio>
                                    <Radio value="N">Só não optantes</Radio>
                                </Form.Control>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={homologar ? 7 : 6} md={12}>
                            <Form.Group controlId="opcaoSimples">
                                <Form.ControlLabel>Opção pelo Simples</Form.ControlLabel>
                                <Form.Control
                                    className="radio-group"
                                    accepter={RadioGroup}
                                    name="opcaoSimples"
                                    inline
                                    appearance="picker"
                                    value={filterFields.opcaoSimples}
                                    onChange={value => setFilterFields({ ...filterFields, opcaoSimples: value })}
                                >
                                    <Radio value="T">Todos</Radio>
                                    <Radio value="S">Só optantes</Radio>
                                    <Radio value="N">Só não optantes</Radio>
                                </Form.Control>
                            </Form.Group>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={homologar ? 4 : 5} className="cnae-sem-cliente">
                            <Stack spacing={30}>
                                <Form.Group controlId="exibirCnaeSemCliente">
                                    <Form.Control
                                        name="exibirCnaeSemCliente"
                                        id="exibirCnaeSemCliente"
                                        accepter={Checkbox}
                                        checked={filterFields.exibirCnaeSemCliente}
                                        onChange={(_, checked) => setFilterFields({ ...filterFields, exibirCnaeSemCliente: checked })}
                                    >
                                        Exibir CNAE's sem clientes
                                    </Form.Control>
                                </Form.Group>
                            </Stack>
                        </FlexboxGrid.Item>

                        <FlexboxGrid.Item as={Col} colspan={24} lg={24}>
                            <Stack className="stack-footer" justifyContent="space-between">
                                <Stack spacing={15}>
                                    <Button color="cyan" appearance="ghost" block onClick={onClickApplyFilter} disabled={loading}>Aplicar filtros</Button>
                                    <Button color="yellow" appearance="ghost" block onClick={handleClickClear} disabled={loading}>Limpar filtros</Button>
                                    <SelectSalvaFiltro
                                        ref={salvaFiltroRef}
                                        idTela={'ESTATISTICA'}
                                        validateParametros={validateSalvaFiltro}
                                        getParametros={() => filterFields}
                                        onSelectFilter={async params => {
                                            setFilterFields({ ...filterFields, ...params })

                                            //definindo os valores para os combos
                                            await selectCnaeRef.current.setValues(params.cnae)
                                            await selectFormaTributacao.current.setValues(params.formaTributacao)
                                            await selectUfRef.current.setValues(params.uf)
                                            await selectRegIntermediariaRef.current.setValues(params.regiaoIntermediaria)
                                            await selectRegImediataRef.current.setValues(params.regiaoImediata)
                                            await selectMunRef.current.setValues(params.municipio)
                                            await selectFaixaCapitalRef.current.setValues(params.faixaCapitalSocial)
                                            await selectFaixaIdadeRef.current.setValues(params.faixaIdadeEmpresa)
                                        }}
                                    />
                                </Stack>
                                <Button color="blue" appearance="ghost" block onClick={handleClickFormatoDemonstracao} disabled={loading}>
                                    {`Exibir dados no formato ${formatoDemonstracao === FormatoDemonstracao.cnae_uf_municipio
                                        ? FormatoDemonstracao.uf_municipio_cnae
                                        : FormatoDemonstracao.cnae_uf_municipio}`}
                                </Button>
                            </Stack>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Form>
            </VerticalTitle>
        </Panel>
    )
}

export default CustomHeader