import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Avatar, CheckPicker, Form, Loader, Stack, Tag, useToaster } from 'rsuite';

import HelpText from '../HelpText';
import { showMessage } from '../ShowMessage';

import './style.css';

const SelectTabelasRFB = forwardRef(({
  controlName,
  controlLabel,
  helpText,
  helpTitle,
  apiSetData,
  groupBy,
  onChange,
  onOpen,
  onClose,
  customLabel,
  customRender,
  value,
  disabled,
  readOnly,
  ...rest
}, ref) => {
  const toaster = useToaster()
  const formGroupRef = useRef()

  const [data, setData] = useState([]);
  const [valueRender, setValueRender] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);

  const [newWidth, setNewWidth] = useState()

  useEffect(() => {
    // Função para obter a largura do Form.Group
    const getNewWidth = () => {
      if (formGroupRef.current) {
        const formGroupWidth = formGroupRef.current.getBoundingClientRect().width - 1;
        const adjustedWidth = Math.ceil(formGroupWidth) - 1;
        setNewWidth(adjustedWidth)
      }
    }

    getNewWidth()

    // Adicionar ouvinte de evento de redimensionamento ao montar o componente
    window.addEventListener('resize', getNewWidth);

    // Remover o ouvinte de evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', getNewWidth);
    };

  }, [formGroupRef])

  const getData = async fromStart => {
    setLoading(true);
    try {
      if (apiSetData) {
        const result = await apiSetData({
          start: fromStart ? 0 : data.length,
          itemsPerPage: 20,
          searchText: searchText
        });
        const rows = result.rows.map(c => ({
          label: customLabel ? customLabel(c) : `${c.codigo} - ${c.descricao}`,
          value: c.codigo,
          object: c,
          ...(groupBy ? { groupBy: c[groupBy] } : {})
        }));
        if (fromStart) {
          setData(rows)
        } else {
          setData([...data, ...rows])
        }
      }
    } catch (error) {
      setData([]);
      showMessage({ toaster, errorObject: error })
    }
    setLoading(false);
  }

  const handleCloseTag = (event, codigo) => {
    event.stopPropagation();
    if (readOnly) return false;
    if (onChange) {
      setValueRender(valueRender.filter(f => f.codigo !== codigo))
      onChange(value.filter(c => c !== codigo))
    }
  }

  const renderValue = () => (
    <div className='picker-value-container'>
      {valueRender.map((item, index) => (
        <Tag key={index} closable onClose={event => handleCloseTag(event, item.codigo)}>
          {customRender ? customRender(item) : `${item.codigo} - ${item.descricao}`}
        </Tag>
      ))}
    </div>
  )

  const loadMore = useCallback(() => (
    <Stack spacing={10} justifyContent='center'>
      <Loader />
      <span>Buscando...</span>
    </Stack>
  ), []);

  const onItemsRendered = async props => {
    if (props.visibleStopIndex >= data.length - 1) await getData();
  };

  const renderMenu = menu => {
    return (
      <>
        {menu}
        {loading && loadMore()}
      </>
    );
  };

  const handleSelect = (values, item) => {
    const newValueRender = valueRender.filter(f => values.includes(f.codigo))

    if (values.includes(item.value) && !valueRender.find(f => f.codigo === item.value)) newValueRender.push(item.object)
    setValueRender(newValueRender)

    if (onChange) onChange(values)
  }

  const handleClean = () => onChange && onChange([]);
  const handleOpen = async () => {
    if (onOpen) onOpen();
    await getData(true);
  }
  const handleClose = () => {
    if (onClose) onClose();
    setSearchText(null);
    setData([]);
  }

  useEffect(() => {
    if (disabled) handleClean()
    // eslint-disable-next-line
  }, [disabled])

  useEffect(() => {
    const delayTypingInputSearch = setTimeout(async () => {
      if (searchText !== null) await getData(true);
    }, 500)

    setLoading(true)
    return () => clearTimeout(delayTypingInputSearch)

    // eslint-disable-next-line
  }, [searchText])

  const setValues = async values => {
    if (values.length) {
      setValueRender([])
      const data = await apiSetData({ arrayCodigos: values })
      setValueRender(data)
    }
  }

  useImperativeHandle(ref, () => ({
    setValues
  }));

  return (
    <Form.Group controlId={controlName} ref={formGroupRef}>
      <Form.ControlLabel>
        <Stack spacing={5}>
          {controlLabel || controlName}
          {helpText && <HelpText text={helpText} title={helpTitle} />}
          {
            value.length > 0 &&
            <Avatar size='xs' circle style={{ background: '#1499ef' }}>
              <span style={{ fontSize: 14 }} >
                {value.length}
              </span>
            </Avatar>
          }
        </Stack>
      </Form.ControlLabel>
      <Form.Control
        {...rest}
        className='select-tabela-rfb'
        style={{ width: newWidth }}
        accepter={CheckPicker}
        name={controlName}
        data={data}
        value={value}
        virtualized
        disabled={disabled}
        groupBy={groupBy ? 'groupBy' : undefined}
        readOnly={readOnly}
        renderValue={renderValue}
        renderMenu={renderMenu}
        listProps={{ onItemsRendered }}
        searchBy={() => true}
        onSearch={setSearchText}
        onOpen={handleOpen}
        onClean={handleClean}
        onSelect={handleSelect}
        onClose={handleClose}
      />
    </Form.Group>
  )
})


export default SelectTabelasRFB